import { PropsWithChildren } from "react"
import styles from "./sidepanel.module.css"
import classNames from "classnames"
import { Button } from "../button/Button"

export const Sidepanel = ({
  children,
  open,
  onClose,
  footer,
}: PropsWithChildren<{
  open: boolean
  onClose?: () => void
  footer?: any
}>) => {
  return (
    <div
      className={classNames(styles.sidepanel, open && styles.open)}
      onClick={(e) => e.stopPropagation()}
    >
      <div className={styles.flexCol}>
        {onClose && (
          <div className={styles.closeButton}>
            <Button variant="inline" onClick={() => onClose()}>
              Aizvērt
            </Button>
          </div>
        )}
        <div className={styles.content}>{children}</div>
        {footer ? <div className={styles.footer}>{footer}</div> : null}
      </div>
    </div>
  )
}
