"use client"

import * as React from "react"
import { Check, ChevronsUpDown } from "lucide-react"

import { cn } from "@/lib/utils"
import { Button } from "@/components/ui/button"
import {
  Command,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem,
  CommandList,
} from "@/components/ui/command"
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "@/components/ui/popover"

type Props = {
  options: { id: string | number | undefined; name: string }[]
  onChange: (value: any[]) => void
  single?: boolean
  selected?: any[]
}

export function Combobox({ single, options, onChange, selected }: Props) {
  const [open, setOpen] = React.useState(false)
  const [value, setValue] = React.useState<(typeof options)[number]["id"][]>(
    selected ?? [],
  )

  return (
    <Popover open={open} onOpenChange={setOpen}>
      <PopoverTrigger asChild>
        <Button
          variant="outline"
          role="combobox"
          aria-expanded={open}
          className="w-full justify-between"
        >
          <span className="w-[100%] overflow-hidden overflow-ellipsis text-left">
            {value.length
              ? options
                  .filter((option) => value.includes(option.id))
                  .map((opt) => opt.name)
                  .join(", ")
              : "Izvēlēties"}
          </span>
          <ChevronsUpDown className="opacity-50" />
        </Button>
      </PopoverTrigger>
      <PopoverContent className="w-[300px] p-0" style={{ zIndex: 999 }}>
        <Command>
          {options.length > 10 && (
            <CommandInput placeholder="Search option..." className="h-9" />
          )}
          <CommandList>
            <CommandEmpty>No option found.</CommandEmpty>
            <CommandGroup>
              {options.map((option, i) => (
                <CommandItem
                  key={option.id ?? `option-${i}`}
                  value={option.id as string}
                  onSelect={() => {
                    let next = value

                    if (value.some((val) => val === option.id)) {
                      next = value.filter((c) => c !== option.id)
                    } else {
                      next = [...value, option.id].filter(
                        (opt) => opt !== undefined,
                      )
                    }
                    if (single) {
                      next = [option.id]
                    }
                    if (option.id === undefined) {
                      next = [option.id]
                    }
                    setValue(next)
                    onChange(next)

                    setOpen(false)
                  }}
                >
                  {option.name}
                  <Check
                    className={cn(
                      "ml-auto",
                      value.some((val) => val == option.id)
                        ? "opacity-100"
                        : "opacity-0",
                    )}
                  />
                </CommandItem>
              ))}
            </CommandGroup>
          </CommandList>
        </Command>
      </PopoverContent>
    </Popover>
  )
}
