import { Project } from "../../../../types"
import { TenantDetails, TenantFeature } from "../../../store/tenant"
import { EditOperation } from "./EditOperation"
import { EditProject } from "./EditProject"

const formatTime = (ms: number) => {
  return `${Math.floor(ms / 60)}h ${ms % 60}min`
}

export const ProjectHeading = ({
  project,
  features,
  onSave,
  showEdit,
  showOpEdit,
}: {
  project: Project
  features: TenantDetails["settings"]["features"]
  onSave?: () => void
  showEdit?: boolean
  showOpEdit?: boolean
}) => {
  const progress = features[TenantFeature.Units] ? project.progress : project.timeProgress
  return (
    <div className="project-heading">
      <div className="title">
        {project.name}
        {project.code && <span>({project.code})</span>}
      </div>
      <div className="info">
        {features[TenantFeature.Normatives] ? (
          <span
            style={{
              fontSize: 16,
              fontWeight: 500,
              color: project.difficulty > 100 ? "var(--error)" : undefined,
            }}
          >
            Normatīvi: {project.difficulty}%
          </span>
        ) : null}
        {features[TenantFeature.Units] ? (
          <span>
            {project.unitsUsed} detaļas ({project.unitsPredicted})
          </span>
        ) : null}
        {features[TenantFeature.Time] ? (
          <span>
            {formatTime(project.timeUsed)} ({formatTime(project.timePredicted)})
          </span>
        ) : null}
        {features[TenantFeature.Salary] ? (
          <span>{(project.salaryPredicted / 100).toFixed(2)}€</span>
        ) : null}
        <span className="status">{progress}%</span>
        {showEdit && <EditProject project={project} onSave={onSave} />}
        {showOpEdit && <EditOperation project={project} onSave={onSave} />}
      </div>
      <div className="bar" style={{ width: `${progress}%` }} />
    </div>
  )
}
